import React from 'react'
import { Link } from 'react-router-dom'
import '../Header/Header.css'
const Header = () => {
  return (
    <div>
    

<div className='container'>
<nav className="navbar  navbar-expand-lg navbar-light   ">
  <div className="container " style={{marginTop:"-60px"}}>
    <Link className="navbar-brand d-inline-block align-text-top " id='logo' to="/"><img src="./assets/Career1.png" style={{width:"230px"}} class="img-fluid" alt="Responsive"/>  </Link>
    <button className="navbar-toggler bg-light "  type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    
    
  <ul className="navbar-nav    mb-2 mb-lg-0" >
    <li className="nav-item    me-1">
      <Link className="nav-link active fs-6 " to='/' aria-current="page" id='col' >Home</Link>
    </li>
    {/* Vertical Line */}
    <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item   ">
      <Link className="nav-link  fs-6" to='/about' id='col'>About</Link>
    </li>
  
    
     {/* Vertical Line */}
     <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item ">
      <Link className="nav-link  fs-6 " to='/contact'  id='col' >Contact</Link>
    </li>
     {/* Vertical Line */}
     <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item ">
      <Link className="nav-link  fs-6 " to='/career' id='col'  >Career</Link>
    </li>
    
  </ul> </div>
  
     
    </div>
</nav>
</div>
    </div>
  )
}

export default Header
