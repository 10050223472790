import React, { useRef, useState } from 'react'
import Layout from '../Components/Layout'
import emailjs from '@emailjs/browser';
import {   ToastContainer ,  toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import WhatsappButton from '../Components/WhatsappButton';

const Contact = () => {
    const form = useRef();
  const [fname, setFName] = useState("")
  const [lname, setLName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const contactUser= (e) => {
        
    e.preventDefault();
    let userData = {fname,lname, email,  message}
    
    if (userData.fname.length===0 || userData.lname.length===0 ||userData.email.length===0 || userData.message.length===0)
     {
        // alert("error");
        toast.error("Please complete the form!", {
            position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
          });
       
    }
    else{
        emailjs
        .sendForm(
            'service_gtl4x1r',
            'template_917m5je',
            form.current, {
                publicKey: 'eBTnknWzQVeVdQXtB',
            })
            .then(
                () => {
                  console.log('SUCCESS!');
                },
                (error) => {
                  console.log('FAILED...', error.text);
                },
              );
              toast.success("Data send!", {
                position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
              });
              // Reset the form after successful submission
    //  form.reset();
    setFName('') 
    setLName('') 
    setEmail('')
    setMessage('')
            }
        }
  return (
    <Layout>
      <WhatsappButton/>
        <div>
        <img src='./assets/contact.png' style={{width:"100%"}} alt='img'/>
        </div>
       {/* Contact Information Section */}
       <div className="container my-5">
        <div className="row">
          {/* Address Section */}
          <div className="col-md-3 col-12 mb-4 mb-md-0">
            <div className="contact-box">
              <h3 className="mb-1 fs-5 text-light">Our Address</h3>
              <div className="info-item text-light">
                {/* <i className="bi bi-geo-alt-fill icon "></i> */}
                Lahore, Pakistan
              </div>
            </div>
          </div>

          {/* Vertical Line */}
          <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center">
            <div className="vertical-line"></div>
          </div>
          {/* Contact Info Section */}
          <div className="col-md-3 col-12 mb-4 mb-md-0">
            <div className="contact-box text-light">
              <h3 className="mb-1 fs-5">Contact Info</h3>
              <div className="info-item">
                <i className="bi bi-phone-fill icon"></i>
                <a href="tel:+923204702032" style={{ textDecoration: 'none', color: '#fff' }}>+92 310-7770417</a>
              </div>
            </div>
          </div>

          {/* Vertical Line */}
          <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center">
            <div className="vertical-line"></div>
          </div>
          {/* Email Section */}
          <div className="col-md-2 col-12">
            <div className="contact-box text-light">
              <h3 className="mb-1 fs-5">Email Us</h3>
              <div className="info-item">
                <i className="bi bi-envelope-fill icon"></i>
                <a href="mailto:info@example.com" style={{ textDecoration: 'none', color: '#fff' }}>hr@careergrownews.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>

        {/* Map Section */}
        <div className="container-fluid">
        <div className="row">
          <div className="col-12">
          <iframe class="contact-map"  title="Your Unique and Descriptive Title" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13599.652139182843!2d74.3046613!3d31.5540012!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919035394d46555%3A0x49100c54ced161f9!2sChauburji%2C%20Block%20B%20New%20Chauburji%20Park%2C%20Lahore%2C%20Punjab%2054000!5e0!3m2!1sen!2s!4v1721374505487!5m2!1sen!2s"  width="100%" height="400"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>

         {/*Section: Content*/}
         <section className="container bg-suc  mb-5" style={{ border:"1px solid white"}}>
        <h4 className="mb-5 text-center mt-5 text-light fs-2 "><strong>Get in Touch</strong></h4>
        <div className="row d-flex justify-content-center">
          <div className="col-md-6">
            <form  className="contact-form text-dark mt-3" ref={form} onSubmit={contactUser} >
              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline" data-mdb-input-init>
                  <input onChange={(e) => setFName(e.target.value)}
                  type="text" className="form-control" id="name" name="name" value={fname} placeholder="Enter your first name" required />
                    <label className="form-label text-light fs-5" htmlFor="form3Example1">First name</label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline" data-mdb-input-init>
                  <input onChange={(e) => setLName(e.target.value)}
                  type="text" className="form-control" id="name" name="name" value={lname} placeholder="Enter your first name" required />
                    <label className="form-label text-light fs-5" htmlFor="form3Example2">Last name</label>
                  </div>
                </div>
              </div>
              {/* Email input */}
              <div className="form-outline mb-4" data-mdb-input-init>
              <input type="email" className="form-control" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" required />
                <label className="form-label text-light fs-5" htmlFor="form3Example3">Email address</label>
              </div>
              {/* Message input */}
              <div className="form-outline mb-4" data-mdb-input-init>
              <textarea
                  className="form-control" id="message" name="message" value={message}  onChange={(e) => setMessage(e.target.value)} rows="5" placeholder="Enter your message" required ></textarea>
                <label className="form-label text-light fs-5" htmlFor="form3Example4">Message</label>
              </div>
             
              {/* Submit button */}
              <button type="submit" className="btn btn-light btn-block  mb-4" data-mdb-ripple-init>
                Send Message
              </button>
             
            </form>
          </div>
        </div>
      </section>
      {/*Section: Content*/}

      <ToastContainer/>
    </Layout>
  )
}

export default Contact
