import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import './JobCard.css'

function Product({ product }) {



  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const updateTime = () => {
      const now = dayjs();
      const postedTime = dayjs(product.postedon);
      const diffInSeconds = now.diff(postedTime, 'second');

      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const remainingSeconds = diffInSeconds % 60;
      const diffInHours = Math.floor(diffInMinutes / 60);
      const remainingMinutes = diffInMinutes % 60;
      const diffInDays = Math.floor(diffInHours / 24);
      const remainingHours = diffInHours % 24;

      if (diffInDays > 0) {
        setTimeAgo(`${diffInDays} day${diffInDays > 1 ? 's' : ''} ${remainingHours} hour${remainingHours > 1 ? 's' : ''} ago`);
      } else if (diffInHours > 0) {
        setTimeAgo(`${diffInHours} hour${diffInHours > 1 ? 's' : ''} ${remainingMinutes} min ago`);
      } else if (diffInMinutes > 0) {
        setTimeAgo(`${diffInMinutes} min ${remainingSeconds} sec ago`);
      } else {
        setTimeAgo(`${remainingSeconds} sec ago`);
      }
    };

    // Initial call to set time ago
    updateTime();

    // Update the time every second
    const intervalId = setInterval(updateTime, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [product.postedon]);

  return (
    <div className='container-fluid'>

      
    
    <div className='container jobcard'>
        <div className='row innerjob d-flex justify-between items-center   '>
            <div className='col-md-8  iner items-start d-grid  '>
                <h1 className='fs-3 fw-bold'> {product.title} ~ {product.company}</h1>
                <p className='fs-5'>{product.jobType} &#x2022; {product.experience} &#x2022; {product.projectType}</p>
                <h5 className='  text-center ms-4' style={{width:"150px", backgroundColor:"#ccc"}}>{product.salary}</h5>

            
            <div className='d-flex   '>
                <h4 className="ms-4 ">Skills:</h4>
                <p><br/>  
                 {/* {product.skills} */}
                {`${product.skills.slice(0,10)}...`}
                </p>
                
                {/* {product.skills.map((skill)=>( */}
                   
                   {/* <p className='text-secondary py-1 me-2 '><br/> {`${product.skills.slice(0,100)},`}</p> */}
                {/* ))} */}
            </div>
        </div>
        <div className='col-md-3 items-center my-auto mx-auto'>
          {/* <h5>{timeAgo}</h5> */}
          <Link to={product.Link}>
            <button type="button" className="btn btn-sm mb-3" id="button">
              Apply Now
            </button>
          </Link>
        </div>
        </div>



        <br/>
       
    </div>
    </div>
   
  );
}

export default Product;
