// import React from 'react';
// import { Link } from 'react-router-dom';

// const WhatsappButton = () => {
//   const buttonStyle = {
//     position: 'fixed',
//     bottom: '5px',
//     right: '5px',
//     zIndex: '1000',
//   };

//   const iconStyle = {
//     fontSize: '24px',
//     marginRight: '8px',
//   };

//   return (
//     <div style={buttonStyle}>
//       <Link
//       to ="https://wa.me/923107770424" target="_blank" rel="noopener noreferrer"   className="btn btn-success"
//       >
//         <i className="fab fa-whatsapp" style={iconStyle}></i>
//         How can i help you?
//       </Link>
//     </div>
//   );
// };

// export default WhatsappButton;
import React from 'react'
import { Link } from 'react-router-dom';

const WhatsappButton = () => {
    const buttonStyle = {
        position: 'fixed',
        bottom: '5px',
        right: '5px',
        zIndex: '1000',
      };
    
      const iconStyle = {
        
        fontSize: '24px',
        marginRight: '8px',
       
      };
    
  return (
    <div style={buttonStyle}>
      <Link
        to="https://wa.me/971562388456" target="_blank" rel="noopener noreferrer"   className="btn btn-success"
      >
        <i className="fab fa-whatsapp" style={iconStyle}></i>
        How can i help you?
      </Link>
    </div>
  )
}

export default WhatsappButton

