import React from 'react'
import { Link } from 'react-router-dom'

const Parallex = () => {
  return (
    <div>
      
       <header className='parallex-header'>
{/* container */}
<div className='container'>
  <div className='header-content text-center'>
  <h5 className="display-3 text-light mb-3 animated slideInDown text-center"><strong>Job</strong></h5>
  <p className='text-light text-center'>
  Job seekers are actively exploring new opportunities and employers are seeking the right candidates to fill their roles. The job search landscape continues to evolve with the changing job market and technological advancements.</p>
                                                <Link className='text-light' to="/" class="btn px-3 mt-auto mx-auto text-light" style={{border:"1px solid white"}}>View Jobs</Link>
                        
  

  </div>

</div>
{/* end of container */}
  </header>

 
 </div>
    
  )
}

export default Parallex
