import React from 'react'
import { Link } from 'react-router-dom'
import '../Footer/Footer.css'

const Footer = () => {
  return (
    <div>
  

      <hr className='text-light mt-5'/>
        <footer class="site-footer ">
    <div class="container ">
      <div class="footer-content ">
        <p class="copyright">&copy; 2024 CareerGrow. All rights reserved.</p>
        <ul class="footer-links">
          <li><Link to="/">Find Jobs</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/contact">Contact us</Link></li>
          <li><Link target='blank' to="https://www.facebook.com/careergrownews"><i class="fa-brands fa-facebook"></i></Link></li>
          <li><Link target='blank' to="https://www.instagram.com/careergrownews?igsh=MjN4bzNpNXZtdWVn"><i class="fa-brands fa-square-instagram"></i></Link></li>
          <li><Link target='blank' to="https://www.linkedin.com/company/careergrow"><i class="fa-brands fa-linkedin"></i></Link></li>
          
        </ul>
      </div>
    </div>
  </footer>

 
    </div>
  )
}

export default Footer
