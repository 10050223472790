import './App.css';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Career from './Pages/Career';
import Careerdetail from './Pages/Careerdetail';
// import products from './Data/JobData';
import Form  from './Pages/Form';
import JobData from './Data/JobData';
// import {db} from './firebase.config'


function App(props) {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/career' element={<Career/>}/>
        {JobData.map((ele)=>(
             <Route
             key={ele.id}
             path={`/jobPlace${ele.id}`}
             element={<Careerdetail  title={ele.title} 
             des={ele.description} JobType={ele.jobType} sal={ele.salary} proType={ele.projectType} location={ele.location}  company={ele.company} skills={ele.skills}
             
             />}
            //  company={props.company} skills={props.skills} />}
           />
         
        ))}
        <Route path='/jobForm/:title' element={<Form/>}/>
      </Routes>
    </Router>
    
    </>
  );
}

export default App;
