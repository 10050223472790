import React, { useState } from "react";
import Filter from "../Components/Filter";
import Product from "../Components/JobCard/JobCard";

function Products({ products, filters }) {
  const [filterProducts, setFilterProducts] = useState(products);

  const [activeFilters, setActiveFilters] = useState([
    { type: "color", options: [] },
    { type: "category", options: [] },
    { type: "sizes", options: [] },
  ]);

  const onUpdateFilter = () => {
    let updatedFilterProducts = products
      .filter((fp) => {
        let options = activeFilters.find((af) => af.type === "color").options;

        if (options.length === 0) {
          return true;
        }

        return options.includes(fp.color.toLowerCase());
      })
      .filter((fp) => {
        let options = activeFilters.find(
          (af) => af.type === "category"
        ).options;

        if (options.length === 0) {
          return true;
        }

        return options.includes(fp.name.toLowerCase());
      })
      .filter((fp) => {
        let options = activeFilters.find((af) => af.type === "sizes").options;

        if (options.length === 0) {
          return true;
        }

        return options.includes(fp.size.toLowerCase());
      });
    setFilterProducts(updatedFilterProducts);
  };

  const reset = () => {
    setFilterProducts(products);
    setActiveFilters([
      { type: "color", options: [] },
      { type: "category", options: [] },
      { type: "sizes", options: [] },
    ]);
  };
  return (
    <div className="text-white">
     
      <div className="container">
                    <div className="row">
                        <div className="col-md-4 ">
                            <div>
                                
                            </div>
                        <aside className="">

              <div className="col-md-4 lg:block " >
                <div
                  className="col-md-2 flex items-center justify-end   "
                  onClick={reset} style={{cursor:"pointer"}}
                >
                  Reset
                </div><br/>
               
                <form className="space-y-10 divide-y divide-gray-200 ">
                  {filters.map((section, sectionIdx) => (
                    <Filter
                      key={section.id}
                      section={section}
                      sectionIdx={sectionIdx}
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      activeFilterOptions={
                        activeFilters.find((af) => af.type === section.id)
                          .options
                      }
                      onUpdateFilter={onUpdateFilter}
                    />
                  ))}
                </form>
              </div>
            </aside>
                        </div>
                        <div className="col-md-8  text-dark pt-2">
                       
              <div className="mx-auto  px-2 sm:px-6 lg:max-w-7xl lg:px-8">
               
                {filterProducts.length === 0 && (
                  <div className="text-center text-light">
                    <h3>Sorry no Job available</h3>
                  </div>
                )}
                <div className=" text-center  ">
                  {filterProducts.length > 0 &&
                    filterProducts.map((product) => (
                      <Product key={product.id} product={product} />
                    ))}
                    
                </div>
              </div>
            
                        </div>

                    </div>

                </div>
    </div>
    
  );
}

export default Products;
